import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import illustration from "../images/undraw_Progress_tracking_re_ulfg.svg";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <section className="text-gray-700 body-font">
      <div className="container mx-auto flex flex-col px-5 py-24 justify-center items-center">
        <img
          className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded"
          alt="hero"
          src={illustration}
        />
        <div className="w-full md:w-2/3 flex flex-col mb-16 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
            Actionable metrics to track your software's quality
          </h1>
          <p className="mb-8 leading-relaxed">
            Our platform provides deep insight into your tech stack's maturity,
            <br />
            so that you can become active instead of reactive.
          </p>
          <div className="flex w-full justify-center">
            <input
              className="border-0 bg-gray-100 rounded mr-4 border border-gray-400 focus:outline-none focus:border-blue-500 text-base px-4 lg:w-full xl:w-1/2 w-2/4 md:w-full"
              placeholder="Enter your email and get an invite"
              type="text"
            />
            <button
              className="inline-flex text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg"
              style={{ backgroundColor: "#0080ff" }}
            >
              Request
            </button>
          </div>
          <p className="text-sm mt-2 text-gray-500 mb-8 w-full">
            Privacy note: We store your email address to get in touch with you.
          </p>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
